@mixin animation-slide-up($delay: 0.2) {
  animation: 'slide-up' 0.4s both $delay + s;
  opacity: 0;
}

@mixin animation-scale-out($delay: 0.2) {
  animation: 'scale-out' 0.4s both $delay + s;
  opacity: 0;
}

@mixin animation-wave-out() {
  animation: 'wave-out' 1.7s infinite;
  opacity: 1;
  transform: scale(0.95);
}

@mixin sp-screen {
  @media screen and (max-width: 599px) {
    @content;
  }
}
