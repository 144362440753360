@mixin work-section {
  &__header {
    @extend .section__header;

    margin-bottom: 62px;
    img {
      margin-bottom: 1rem;
      opacity: 0;
      vertical-align: middle;
      &.is-active-animation {
        @include animation-scale-out;
      }
    }
    h3 {
      opacity: 0;
      padding-left: 10px;
      &.is-active-animation {
        @include animation-slide-up;
      }
    }
  }
  &__images {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    justify-content: space-between;
    list-style: none;
    margin: auto;
    width: 905px;

    @include sp-screen {
      justify-content: center;
      padding: 0 16px;
      width: auto;
    }
    li {
      height: 217px;
      width: 290px;
      img {
        height: 100%;
        object-fit: cover;
        vertical-align: middle;
        width: 100%;
      }

      @for $count from 1 to 4 {
        &:nth-of-type(3n + #{$count}) {
          opacity: 0;
          &.is-active-animation {
            @include animation-slide-up($count * 0.2);
          }
        }
      }

      @include sp-screen {
        height: 236px;
        justify-content: center;
        opacity: 0;
        width: auto;
        &.is-active-animation {
          @include animation-slide-up(0);
        }
      }
    }
  }
}

.works {
  @extend .anchor;
  &__inner {
    padding-bottom: 40px;
    padding-top: 60px;

    @include sp-screen {
      padding-top: 30px;
    }
  }
  &__header {
    @extend .section__header;

    margin-bottom: 55px;
  }
  &__CM {
    margin-bottom: 115px;

    @include sp-screen {
      margin-bottom: 90px;
    }
    .CM {
      @include work-section;
    }
  }
  &__PA {
    margin-bottom: 115px;

    @include sp-screen {
      margin-bottom: 90px;
    }
    .PA {
      @include work-section;
    }
  }
  &__TV {
    margin-bottom: 115px;

    @include sp-screen {
      margin-bottom: 20px;
    }
    .TV {
      @include work-section;
    }
  }
}
