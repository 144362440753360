.header {
  background-color: rgba($back-ground-color, 0.55);
  left: 0;
  overflow-x: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  @include sp-screen {
    background-color: transparent;
    overflow: hidden;
    pointer-events: none;
  }
  &__inner {
    @extend .section__inner;

    align-items: center;
    display: flex;
    height: $header-height;
    padding: 0 10px;

    @include sp-screen {
      align-items: flex-start;
      background-color: $back-ground-color;
      flex-direction: column;
      gap: 30px;
      height: 100vh;
      opacity: 0;
      padding-top: 60px;
      pointer-events: all;
      transform: translateX(100%);
      transition: transform 0.4s, opacity 0.4s;
      &.is-active {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  &__menu-button {
    display: none;

    @include sp-screen {
      border-top: 2px solid $white;
      display: block;
      filter: drop-shadow(0 0 1px $back-ground-color);
      height: 40px;
      margin-right: 1.5rem;
      margin-top: 1.9rem;
      pointer-events: all;
      position: fixed;
      right: 0;
      top: 0;
      transition: border-color 0.3s;
      width: 40px;
      z-index: 1;
      &::before,
      &::after {
        backface-visibility: hidden;
        border-top: 2px solid $white;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        transform: rotate(0);
        transition: transform 0.3s, width 0.3s, top 0.3s;
        width: 30px;
      }
      &::before {
        top: 12px;
      }
      &::after {
        top: 26px;
      }
      &.is-active {
        border-color: transparent;
        &::before {
          top: 50%;
          transform: rotate(45deg);
          width: 40px;
        }
        &::after {
          top: 50%;
          transform: rotate(-45deg);
          width: 40px;
        }
      }
    }
  }
  &__links {
    display: flex;
    list-style: none;
    margin-left: 56px;

    @include sp-screen {
      flex-direction: column;
      flex-wrap: wrap;
      margin-left: 5px;
    }
  }
  &__link-item {
    $width: 6px;

    padding-left: $width;
    position: relative;
    &::before {
      background-color: $white;
      content: '';
      height: 1.25rem;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: $width;
    }
  }
  &__link {
    color: $white;
    display: inline-block;
    font-size: 1.125rem;
    letter-spacing: 0.17rem;
    margin: 0 1.05rem 1rem 0.25rem;
    padding: 1.25rem 0.2rem 0.25rem 1rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;

    @include sp-screen {
      font-size: 1rem;
      line-height: 1.5em;
      min-width: 50vw;
    }
    &::before {
      background-color: $white;
      bottom: 0;
      content: '';
      height: 0.1rem;
      left: 0;
      position: absolute;
      transition: width 0.4s;
      width: 0;

      @include sp-screen {
        display: none;
      }
    }
    &:hover::before {
      width: 100%;
    }
  }
}
