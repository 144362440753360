.contact {
  @extend .anchor;

  color: $black;
  text-align: center;
  &__inner {
    background-color: #ebedec;
    min-height: 770px;
    padding-bottom: 130px;
    padding-top: 100px;

    @include sp-screen {
      min-height: auto;
      padding-bottom: 110px;
    }
  }
  &__header {
    @extend .section__header;

    margin-bottom: 65px;

    @include sp-screen {
      margin-bottom: 45px;
    }
  }
  &__icon {
    margin-bottom: 65px;

    @include sp-screen {
      margin-bottom: 45px;
      img {
        width: 100px;
      }
    }
  }
  &__tel {
    font-size: 18px;
    margin-bottom: 60px;

    @include sp-screen {
      font-size: 16px;
      margin-bottom: 40px;
    }
    p {
      &:not(:last-of-type) {
        margin-bottom: 1.2em;
      }
    }
  }
  &__message {
    font-size: 20px;
    margin-bottom: 2em;

    @include sp-screen {
      font-size: 18px;
      line-height: 1.5em;
      word-break: keep-all;
    }
  }
  &__mails {
    display: flex;
    justify-content: center;
    margin-left: 1.5em;

    @include sp-screen {
      flex-wrap: wrap;
      margin-left: 0;
    }
  }
  &__mail {
    padding-top: 50px;
    position: relative;

    @include sp-screen {
      width: 100%;
      &:first-of-type {
        margin-bottom: 2rem;
      }
    }
    &:not(:first-of-type) {
      margin-left: 2rem;

      @include sp-screen {
        margin-left: 0.8rem;
      }
      &::before {
        content: '/';
        display: block;
        margin-right: 0.8rem;
        position: absolute;
        right: 100%;

        @include sp-screen {
          content: '';
          padding: 0;
        }
      }
    }
    img {
      height: 30px;
      left: 0;
      object-fit: contain;
      object-position: center;
      position: absolute;
      top: 0;
      width: 100%;
    }
    a {
      color: $blue;
    }
  }
}
