.video {
  @extend .anchor;

  color: $black;
  text-align: center;
  &__inner {
    background-color: #ebedec;
    padding-bottom: 130px;
    padding-top: 100px;
  }
  &__header {
    @extend .section__header;

    margin-bottom: 65px;
  }
  &__content-wrap {
    padding-left: 190px;

    @include sp-screen {
      padding-left: 0;
    }
  }
  &__frame {
    left: 0;
    margin: auto;
    position: relative;
    width: 720px;

    @include sp-screen {
      padding: 0 16px;
      width: auto;
    }
    img {
      left: 0;
      pointer-events: none;
      position: absolute;
      user-select: none;
      z-index: 1;

      @include sp-screen {
        display: none;
      }
    }
  }
  &__content {
    background-color: $back-ground-color;
    border-radius: 45px;
    height: 364px;
    position: relative;
    width: 535px;

    @include sp-screen {
      height: calc(100vw - 20px);
      width: auto;
    }
  }
}
