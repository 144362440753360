.company {
  @extend .anchor;
  &__inner {
    padding-bottom: 110px;
    padding-top: 100px;

    @include sp-screen {
      padding-bottom: 70px;
    }
  }
  &__header {
    @extend .section__header;

    margin-bottom: 90px;
  }
  dl {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    letter-spacing: 2px;
    margin: auto;
    width: 440px;

    @include sp-screen {
      display: block;
      font-size: 16px;
      text-align: center;
      width: auto;
    }
    dt {
      width: 26%;
      &::before {
        content: '■';
        padding-right: 2px;
      }

      @include sp-screen {
        margin-bottom: 9px;
        margin-top: 2px;
        width: auto;
      }
    }
    dd {
      width: 74%;

      @include sp-screen {
        width: auto;
      }
      p {
        margin-bottom: 1.5em;
      }
    }
  }
  section {
    h3 {
      align-items: center;
      border: 3px solid #fff;
      display: flex;
      height: 63px;
      justify-content: center;
      letter-spacing: 3px;
      margin: auto;
      margin-bottom: 57px;
      margin-top: 90px;
      text-transform: uppercase;
      width: 168px;
    }
  }
}

.member {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
    justify-content: space-around;
    list-style: none;
    margin: auto;
    width: 752px;

    @include sp-screen {
      padding: 0 10px;
      width: auto;
    }
    li {
      height: 154px;
      width: 154px;
      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }

      @for $count from 1 to 5 {
        &:nth-of-type(4n + #{$count}) {
          opacity: 0;
          &.is-active-animation {
            @include animation-scale-out($count * 0.2);
          }
        }
      }
    }
  }
}

.access {
  p {
    font-size: 18px;
    letter-spacing: 1px;
    text-align: center;
    &:not(:last-of-type) {
      margin-bottom: 1.5em;
    }

    @include sp-screen {
      font-size: 16px;
      line-height: 1.5em;
      padding: 0 2rem;
      word-break: keep-all;
      &:not(:last-of-type) {
        margin-bottom: 1.2em;
      }
    }
  }
  &__address,
  &__train {
    margin-bottom: 4em;
  }
  &__bus {
    margin-bottom: 80px;
  }
  &__map {
    height: 470px;
    margin: auto;
    position: relative;
    width: 720px;

    @include sp-screen {
      height: 100vw;
      width: auto;
    }
  }
}
