@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scale-out {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes wave-out {
  10% {
    border-width: 2px;
    filter: blur(0);
    opacity: 1;
    transform: scale(0.95);
  }
  70% {
    border-width: 5px;
    filter: blur(1px);
    opacity: 0;
    transform: scale(1.25);
  }
  100% {
    border-width: 5px;
    filter: blur(1px);
    opacity: 0;
    transform: scale(1.25);
  }
}
