@import 'ress';
@import './variables';
@import './animation';
@import './mixin';
@import './header';
@import './hero';
@import './works';
@import './equipment';
@import './video';
@import './company';
@import './contact';

body {
  background-color: $back-ground-color;
  color: $white;
  font-family: 'BIZ UDPゴシック', sans-serif;
  padding-top: $header-height;

  @include sp-screen {
    padding-top: 0;
  }
}

.section__inner {
  margin: auto;
  width: $screen-width;

  @include sp-screen {
    width: auto;
  }
}

.section__header {
  text-align: center;
  h2 {
    font-size: 30px;
    letter-spacing: 4px;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
  }
  h3 {
    font-size: 16px;
    letter-spacing: 4px;
  }
  p {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 3px;
  }
}

.anchor {
  margin-top: -$header-height;
  padding-top: $header-height;

  @include sp-screen {
    margin-top: 0;
    padding-top: 0;
  }
}
