.equipment {
  @extend .anchor;
  &__inner {
    background-image: url('../assets/equipment_bg.jpg');
    background-position-y: -95px;
    background-size: cover;
    padding-bottom: 180px;
    padding-top: 100px;

    @include sp-screen {
      background-position-y: 0;
    }
  }
  &__header {
    @extend .section__header;

    margin-bottom: 90px;
  }
  &__list {
    display: flex;
    gap: 130px;
    justify-content: center;
    list-style: none;

    @include sp-screen {
      align-items: center;
      flex-direction: column;
    }
    li {
      text-align: center;
      a {
        display: block;
        text-decoration: none;
        p {
          color: $white;
          font-weight: bold;
          margin-bottom: 44px;
          text-transform: uppercase;
        }
        & > .equipment-icon__frame {
          height: 118px;
          margin-bottom: 30px;
          position: relative;
          width: 118px;
          &::before {
            border: 1px solid $white;
            border-radius: 50%;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transform: scale(0.95);
            width: 100%;
          }
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
        &:hover > .equipment-icon__frame {
          &::before {
            @include animation-wave-out;

            @include sp-screen {
              animation: none;
            }
          }
        }
        .file-icon {
          display: block;
          margin-left: -20px;
          text-align: left;
        }
      }
    }
  }
}
